<template>
    <v-layout d-flex column class="my-3">
        <v-simple-table>
            <thead>
            <tr>
                <th>Artikelnummer</th>
                <th>Hersteller</th>
                <th>Beschreibung</th>
                <th>Status</th>
                <th>Einzelpreis</th>
                <th>Menge</th>
                <th>Summe</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(position, key) in item.detail.positions" :key="key">
                <td>{{position['@attributes'].herstellernummer}}</td>
                <td>{{position['@attributes'].hersteller}}</td>
                <td>{{position['@attributes'].beschreibung}}</td>
                <td :class="[position['@attributes'].status]">{{$t('bills.status.' + position['@attributes'].status)}}</td>
                <td>{{position['@attributes'].einzelpreis_brutto | currency}}</td>
                <td>{{position['@attributes'].menge}}</td>
                <td>{{position['@attributes'].positionspreis_brutto | currency}}</td>
            </tr>
            </tbody>
        </v-simple-table>
        <v-form ref="form" v-model="valid" @submit.prevent="save" class="mt-3">
            <v-list dense>
                <v-list-item v-for="(note, key) in item.detail.notes" :key="key" two-line>
                    <v-icon>
                        mdi-chevron-right
                    </v-icon>
                    <v-list-item-content>
                        <v-list-item-subtitle>
                            <span>{{note['@attributes'].erstellt | date}}</span>
                            <span v-if="note['@attributes'].typ === 'server'"> ({{note['@attributes'].mitarbeiter}})</span>
                        </v-list-item-subtitle>
                        <v-list-item-title>
                            {{note['@attributes'].text}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-divider/>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-text-field :rules="rules.note" v-model="note" label="Neue Nachricht" counter="150" append-icon="mdi-send" @click:append="save"/>
                </v-list-item>
            </v-list>
        </v-form>
        <!--<v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>DEBUG</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <pre>{{item.detail.full}}</pre>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>-->
    </v-layout>
</template>

<script>
    export default {
        name: "OrylBillDetails",
        props: ['item'],
        data: function () {
            return {
                note: '',
                rules: {
                    note: [
                        value => !!value || this.$t('bills.note.min'),
                        value => value.length <= 150 || this.$t('bills.note.max')
                    ]
                },
                valid: false
            }
        },
        methods: {
            save: function () {
                if (!this.$refs.form.validate()) {
                    return;
                }

                this.$store.dispatch('BillsStore/saveNote', {...this.item, note: this.note})
                .finally(() => {
                    this.note = '';
                });
            }
        }
    }
</script>

<style scoped>

</style>
