<template>
    <oryl-container>
        <v-card class="pa-3 mb-3">
            <v-text-field :label="$t('bills.search')" v-model="search" clearable/>
            <v-tabs v-model="type" @change="loadBills" fixed-tabs>
                <v-tab>{{ $t('bills.bestellunge') }}</v-tab>
                <v-tab>{{ $t('bills.anfragen') }}</v-tab>
                <v-tab>{{ $t('bills.deliverynotes') }}</v-tab>
                <v-tab>{{ $t('bills.offers') }}</v-tab>
                <v-tab>{{ $t('bills.bills') }}</v-tab>
            </v-tabs>

            <v-data-table show-expand single-expand :headers="headers.orders" :items="bills" :search="search" :loading="loading">
                <template v-slot:item.status="{item}">
                    <v-icon :class="[item.status]">
                        mdi-circle-double
                    </v-icon>
                    {{$t('bills.status.' + item.status)}}
                </template>
                <template v-slot:item.del="{item}">
                    <v-btn v-if="(item.del)" icon @click="delItem(item)" color="red" :loading="loadingPdf === item">
                        <v-icon large>
                            mdi-delete-forever-outline
                        </v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.pdf="{item}">
                    <v-btn icon @click="openPDF(item)" color="red" :loading="loadingPdf === item">
                        <v-icon large>
                            mdi-file-pdf-box
                        </v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.csv="{item}">
                    <v-btn icon @click="openCSV(item)" color="green" :loading="loadingCsv === item" disabled>
                        <v-icon large>
                            mdi-google-spreadsheet
                        </v-icon>
                    </v-btn>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <oryl-bill-details :item="item"/>
                    </td>
                </template>
            </v-data-table>
        </v-card>

        <v-card v-if="type === 0">
            <v-toolbar color="primary" flat dense dark>{{ $t('bills.openpositions') }}</v-toolbar>
            <div class="pa-3">
                <v-data-table :headers="headers.positions" :items="openPositions" item-key="key">
                    <template v-slot:item.status="{item}">
                        <v-icon :class="[item.status]">
                            mdi-circle-double
                        </v-icon>
                        {{$t('bills.status.' + item.status)}}
                    </template>
                </v-data-table>
            </div>
        </v-card>

        <v-overlay :value="pdfString">
            <v-sheet color="white" tile>
                <v-toolbar color="info" tile dense flat>
                    <v-spacer/>
                    <v-btn icon @click="pdfString = null">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-toolbar>
                <object :data="pdf" class="pdfpreview"/>
            </v-sheet>
        </v-overlay>

        <v-snackbar absolute bottom :value="error" color="error">
            <v-layout d-flex justify-center>
                {{error}}
            </v-layout>
        </v-snackbar>
    </oryl-container>
</template>

<script>
    import OrylContainer from "../../components/OrylContainer";
    import OrylBillDetails from "../../components/OrylBillDetails";
    export default {
        name: "Bills",
        components: {OrylBillDetails, OrylContainer},
        data: function () {
            return {
                search: '',
                pdfString: '',
                type: 0,
                loading: false,
                loadingPdf: null,
                loadingCsv: null,
                error: null,
                headers: {
                    orders: [
                        {text: this.$t('bills.id'), value: 'id'},
                        {text: this.$t('bills.ext'), value: 'ext'},
                        {text: this.$t('bills.date'), value: 'date'},
                        {text: this.$t('bills.status.name'), value: 'status'},
                        {text: this.$t('bills.netto'), value: 'netto'},
                        {text: this.$t('bills.brutto'), value: 'brutto'},
                        {text: 'PDF', value: 'pdf'},
                        {text: this.$t('bills.delete'), value: 'del'},
                    ],
                    positions:  [
                        {text: this.$t('bills.ext'), value: 'ext'},
                        {text: this.$t('bills.brand'), value: 'brand'},
                        {text: this.$t('bills.amount'), value: 'amount'},
                        {text: this.$t('bills.status.name'), value: 'status'},
                        {text: this.$t('bills.id'), value: 'id'},
                    ]
                }
            }
        },
        computed: {
            bills: function () {
                let self = this;

                return this.$store.state.BillsStore.bills.map(function (item) {
                    item.date = self.$options.filters.date(item.date);
                    return item;
                });
            },
            translatedType: function () {
                let pos = [
                    'bestellung',
                    'anfrage',
                    'lieferschein',
                    'angebot',
                    'rechnung',
                ];

                return pos[this.type];
            },
            pdf: function () {
                return 'data:application/pdf;base64,' + this.pdfString;
            },
            openPositions: function () {
                let index = 0;

                return this.bills.reduce(function (prev, current) {
                    return prev.concat(current.detail.positions);
                }, [])
                    .filter(function (item) {
                        return item['@attributes'].status === 'reserviert'
                            || item['@attributes'].status === 'rueckstand';
                    })
                    .map(function (item) {
                        index++;

                        return {
                            id: item['@attributes']['auftragsnummer'],
                            ext: item['@attributes']['herstellernummer'],
                            brand: item['@attributes']['hersteller'],
                            status: item['@attributes']['status'],
                            amount: item['@attributes']['menge'],
                            key: index,
                        }
                    });
            }
        },
        methods: {
            loadBills: function () {
                this.loading = true;

                this.$store.dispatch('BillsStore/getBills', this.translatedType)
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onlyAnfragen: function (item) {
              if (this.translatedType !== 'anfrage') return false;
              var res = true;
              item.detail.positions.forEach(function(item) {
                if (item['@attributes'].status !== 'angefragt') res = false;
              });
              return res;
            },
            openPDF: function (item) {
                this.error = null;
                this.loadingPdf = item;

                this.$store.dispatch('BillsStore/getPdf', item)
                .then((response) => {
                    this.pdfString = response.pdf;
                })
                .catch(() => {
                    this.error = this.$t('bills.nopdf');
                })
                .finally(() => {
                    this.loadingPdf = null;
                });
            },
            openCSV: function (item) {
                this.error = null;
                this.loadingCsv = item;

                this.$store.dispatch('BillsStore/getCsv', item)
                .then((response) => {
                  console.log(response);
                })
                .catch(() => {
                    this.error = this.$t('bills.nocsv');
                })
                .finally(() => {
                    this.loadingCsv = null;
                });
            },
            delItem: function (item) {
              this.$store.dispatch('BillsStore/delAnfrage', item)
                  .then((response) => {
                    console.log(response);
                  })
            }
        },
        created() {
            this.loadBills();
        }
    }
</script>

<style>
    .pdfpreview {
        width: 80vw;
        height: 80vh;
    }

    .geliefert, .bezahlt, .verrechnet {
        color: green !important;
    }

    .reserviert, .offen, .aktiv {
        color: orange !important;
    }

    .rueckstand {
        color: red !important;
    }
</style>
