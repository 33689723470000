var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('oryl-container',[_c('v-card',{staticClass:"pa-3 mb-3"},[_c('v-text-field',{attrs:{"label":_vm.$t('bills.search'),"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tabs',{attrs:{"fixed-tabs":""},on:{"change":_vm.loadBills},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('bills.bestellunge')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('bills.anfragen')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('bills.deliverynotes')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('bills.offers')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('bills.bills')))])],1),_c('v-data-table',{attrs:{"show-expand":"","single-expand":"","headers":_vm.headers.orders,"items":_vm.bills,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:[item.status]},[_vm._v(" mdi-circle-double ")]),_vm._v(" "+_vm._s(_vm.$t('bills.status.' + item.status))+" ")]}},{key:"item.del",fn:function(ref){
var item = ref.item;
return [((item.del))?_c('v-btn',{attrs:{"icon":"","color":"red","loading":_vm.loadingPdf === item},on:{"click":function($event){return _vm.delItem(item)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-delete-forever-outline ")])],1):_vm._e()]}},{key:"item.pdf",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"red","loading":_vm.loadingPdf === item},on:{"click":function($event){return _vm.openPDF(item)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-file-pdf-box ")])],1)]}},{key:"item.csv",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"green","loading":_vm.loadingCsv === item,"disabled":""},on:{"click":function($event){return _vm.openCSV(item)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-google-spreadsheet ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('oryl-bill-details',{attrs:{"item":item}})],1)]}}])})],1),(_vm.type === 0)?_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","flat":"","dense":"","dark":""}},[_vm._v(_vm._s(_vm.$t('bills.openpositions')))]),_c('div',{staticClass:"pa-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers.positions,"items":_vm.openPositions,"item-key":"key"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:[item.status]},[_vm._v(" mdi-circle-double ")]),_vm._v(" "+_vm._s(_vm.$t('bills.status.' + item.status))+" ")]}}],null,false,2539814666)})],1)],1):_vm._e(),_c('v-overlay',{attrs:{"value":_vm.pdfString}},[_c('v-sheet',{attrs:{"color":"white","tile":""}},[_c('v-toolbar',{attrs:{"color":"info","tile":"","dense":"","flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.pdfString = null}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('object',{staticClass:"pdfpreview",attrs:{"data":_vm.pdf}})],1)],1),_c('v-snackbar',{attrs:{"absolute":"","bottom":"","value":_vm.error,"color":"error"}},[_c('v-layout',{attrs:{"d-flex":"","justify-center":""}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }